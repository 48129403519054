import { render, staticRenderFns } from "./OrganismTitleAndDescription.vue?vue&type=template&id=198f52ab&scoped=true&"
import script from "./OrganismTitleAndDescription.vue?vue&type=script&lang=js&"
export * from "./OrganismTitleAndDescription.vue?vue&type=script&lang=js&"
import style0 from "./OrganismTitleAndDescription.vue?vue&type=style&index=0&id=198f52ab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198f52ab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitleAndDescription: require('/app/components/atoms/common/AtomTitleAndDescription.vue').default,MoleculesCommonMoleculeTitleAndDescription: require('/app/components/molecules/common/MoleculeTitleAndDescription.vue').default})
